import React from "react";
import { Link } from "gatsby";
import * as styles from "./PostCell.module.css";
import { Card, Button } from 'react-bootstrap'


const PostCell = (props) => {
  const { title, content, category, published_at, slug, thumbnail } = props.post;

  const plainContent = (content || "").replace(/(<([^>]+)>)/gi, "");
  return (

    <Link to={`/posts/${slug}`} className={styles.link}>

      <Card className={styles.container} >

        <Card.Img className="m-0" variant="top" src={thumbnail} alt={title} style={{ width: `384px`, height: `216px` }}></Card.Img>
        <Card.Body className={styles.cardContents} >
          <Card.Title className={styles.cardTitle}> {title}</Card.Title>
        </Card.Body>
        <Card.Footer style={{ position: `absolute`, bottom: `0`, width: `100%` }}>

          {published_at}

          <Link to={`/categories/${category.slug}`} className={styles.link}>
            <Button className={styles.categoryButton} variant='warning' style={{ padding: `5px`, fontSize: `10px`, margin: `5px` }}>{category.viewName}</Button></Link>

        </Card.Footer>
      </Card>
    </Link>






  );
};

export default PostCell;